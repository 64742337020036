import HttpProxy from '@/services/HttpProxy';

export default class AccountService {
  static async emailVerificationSucceeded(token) {
    const response = await HttpProxy.submit({ method: 'post', url: `Account/EmailConfirmation/${token}`, data: {} });
    return response?.data ?? null;
  }

  static async resendEmailVerification(data) {
    const response = await HttpProxy.submit({ method: 'post', url: 'Account/SendEmailVerification', data });
    return response?.data ?? null;
  }
}
