<template>
  <div></div>
</template>
<script>
import error from '@/mixins/error';
import AccountService from '@/services/AccountService';

export default {
  name: 'VerificationIndex',
  mixins: [error],
  data() {
    return {
      errorMessage: null,
    }
  },
  mounted() {
    this.emailVerificationSucceeded(this.$route.params.token);
  },
  methods: {
    async emailVerificationSucceeded(token) {
      try {
        await AccountService.emailVerificationSucceeded(token);
        this.$swal(
          'Verificación Exitosa',
          'Todo listo, tu correo electrónico ha sido comprobado exitosamente',
          'success',
        ).then((result) => {
          if (result.isConfirmed) {
            this.$router.push({ name: 'login.index' });
          }
        });
      } catch (e) {
        this.$swal(
          'Verificación Fallida',
          'Ocurrio un error al hacer la comprobacion de tu correo electrónico',
          'error',
        ).then((result) => {
          if (result.isConfirmed) {
            this.$router.push({ name: 'landing.index' });
          }
        });
      }
    },
  },
}
</script>
